import { Role } from '../../../../../shared/enums/role.enum';
import { SecondarySideMenuI } from '../../../../departments/interfaces/secondary-side-menu.interface';

export const SIDE_MENU_ITEMS: SecondarySideMenuI[] = [
  {
    translationPath: 'sideMenu.courses',
    activeIcon: 'assets/svgs/side-menu/active-course.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-course.svg',
    route: (programID: string | number) => `programs/${programID}/courses`,
    alt: 'Courses',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
    ],
  },
  {
    translationPath: 'sideMenu.students',
    activeIcon: 'assets/svgs/side-menu/active-student.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-student.svg',
    route: (programID: string | number) => `programs/${programID}/students`,
    alt: 'Students',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
    ],
  },
  {
    translationPath: 'sideMenu.plos',
    activeIcon: 'assets/svgs/side-menu/active-course.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-course.svg',
    route: (programID: string | number) => `programs/${programID}/plos`,
    alt: 'Plos',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
    ],
  },
  {
    translationPath: 'sideMenu.evaluationCriterion',
    activeIcon: 'assets/svgs/side-menu/active-evaluation-criterion.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-evaluation-criterion.svg',
    route: (programID: string | number) =>
      `programs/${programID}/evaluation-criteria`,
    alt: 'Evaluation Criteria',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
    ],
  },
];
