import { Role } from '../../../../shared/enums/role.enum';
import { NavItemI } from '../../../../shared/interfaces/nav-item.interface';

export const SIDE_MENU_ITEMS: NavItemI[] = [
  {
    translationPath: 'sideMenu.home',
    activeIcon: 'assets/svgs/side-menu/active-home.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-home.svg',
    route: ['', 'home'],
    alt: 'Home',
    roles: [Role.INSTRUCTOR, Role.STUDENT],
  },

  {
    translationPath: 'sideMenu.faculties',
    activeIcon: 'assets/svgs/side-menu/active-faculty.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-faculty.svg',
    route: ['faculties'],
    alt: 'Faculties',
    roles: [Role.SUPER_ADMIN, Role.ADMIN],
  },
  {
    translationPath: 'sideMenu.departments',
    activeIcon: 'assets/svgs/side-menu/active-department.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-department.svg',
    route: ['departments'],
    alt: 'departments',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN],
  },
  {
    translationPath: 'sideMenu.programs',
    activeIcon: 'assets/svgs/side-menu/active-program.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-program.svg',
    route: ['programs'],
    alt: 'Programs',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN, Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER],
  },
  {
    translationPath: 'AoL Prog.',
    activeIcon: 'assets/svgs/side-menu/active-program.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-program.svg',
    route: ['aol-programs'],
    alt: 'AoL Programs',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN, Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER],
  },
  {
    translationPath: 'sideMenu.courses',
    activeIcon: 'assets/svgs/side-menu/active-course.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-course.svg',
    route: ['courses'],
    alt: 'Courses',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
      Role.COORDINATOR,
    ],
  },
  {
    translationPath: 'sideMenu.instructors',
    activeIcon: 'assets/svgs/side-menu/active-instructor.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-instructor.svg',
    route: ['instructors'],
    alt: 'Instructors',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN, Role.DEPARTMENT_MANAGER],
  },
  {
    translationPath: 'sideMenu.students',
    activeIcon: 'assets/svgs/side-menu/active-student.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-student.svg',
    route: ['students'],
    alt: 'Students',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
    ],
  },
  {
    translationPath: 'sideMenu.academicYear',
    activeIcon: 'assets/svgs/side-menu/active-academic-year.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-academic-year.svg',
    route: ['academic-years'],
    alt: 'Academic Year',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
      Role.COORDINATOR,
    ],
  },
];
