<article class="university-container">
  <section class="university-logo-container">
    <figure>
      <img src="assets/images/code-mint-logo.png" alt="PSUT Logo" />
    </figure>
  </section>
  <section class="university-name-container">
    <h1>
      {{
        this.isMobileScreen
          ? ""
          : this.isTabletScreen
          ? ("universityAcronym" | translate)
          : ("universityName" | translate)
      }}
    </h1>
  </section>
</article>
