<article class="program-sub-domain-container">
  <!-- <app-stat-cards-with-main-card
    [config]="{ cards: this.programsCard, mainCard: {name: this.selectedProgram?.['program.name' | translate], img: this.selectedProgram?.['logo']?.url ,manager: this.selectedProgram?.programManager?.['user.name' | translate]} }"
  ></app-stat-cards-with-main-card> -->

  <div class="program-sub-domain">
    <app-programs-details-side-menu
      [selectedProgram]="selectedProgram!"
    ></app-programs-details-side-menu>

    <div class="sub-domain"><router-outlet></router-outlet></div>
  </div>
</article>
