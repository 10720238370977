<article class="department-sub-domain-container">
  <!-- <app-stat-cards-with-main-card
    [config]="{ cards: this.departmentsCard, mainCard: {name: this.selectedDepartment?.['department.name' | translate], img: this.selectedDepartment?.['logo']?.url ,manager: this.selectedDepartment?.departmentManager?.['user.name' | translate]} }"
  ></app-stat-cards-with-main-card> -->

  <div class="department-sub-domain">
    <app-departments-details-side-menu
      [selectedDepartment]="selectedDepartment!"
    ></app-departments-details-side-menu>

    <div class="sub-domain"><router-outlet></router-outlet></div>
  </div>
</article>
