import { NgFor, NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StatCardsWithMainCardInputI } from './interfaces/stat-cards-with-main-card-input.interface';

@Component({
  selector: 'app-stat-cards-with-main-card',
  standalone: true,
  imports: [TranslateModule, NgFor, NgIf],
  templateUrl: './stat-cards-with-main-card.component.html',
  styleUrl: './stat-cards-with-main-card.component.scss',
})
export class StatCardsWithMainCardComponent {
  config = input.required<StatCardsWithMainCardInputI>();
}
