import { NavItemI } from '../../../../shared/interfaces/nav-item.interface';

export const SIDE_MENU_ITEMS: NavItemI[] = [
  {
    translationPath: 'forgetPassword.form.password',
    activeIcon: 'assets/svgs/side-menu/active-settings.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-settings.svg',
    route: ['settings/change-password'],
    alt: 'Change Password',
  },
];
