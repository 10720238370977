<aside class="side-menu" [style.width]="this.isSideMenuOpen ? '169px' : '60px'">
  <img
    class="toggle-button"
    src="assets/svgs/side-menu/side-menu-toggle.svg"
    alt="toggle button"
    [style.left]="this.isSideMenuOpen ? '156px' : '46px'"
    [style.right]="this.isSideMenuOpen ? '156px' : '46px'"
    [style.transform]="
      this.isRtl()
        ? this.isSideMenuOpen
          ? 'rotate(0deg)'
          : 'rotate(180deg)'
        : this.isSideMenuOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)'
    "
    (click)="openCloseSideMenu()"
  />
  <section class="logo-container">
    <figure>
      <img src="assets/svgs/side-menu/eduminty.svg" alt="EduMinty logo" />
    </figure>
  </section>
  <nav class="main-nav-list">
    <ul>
      @for (sideMenuItem of sideMenuItems; track sideMenuItem; let index =
      $index) { @if (this.isRoleAllowedToItem(sideMenuItem.roles)) { @if
      (this.isSideMenuOpen) {
      <li>
        <a
          [routerLink]="sideMenuItem.route[0]"
          [ngClass]="{ active: this.isCurrentRouteActive(sideMenuItem) }"
          ariaCurrentWhenActive="page"
          [style.width]="this.isSideMenuOpen ? '90%' : 'fit-content'"
          (mouseenter)="this.itemOnHover = index"
          (mouseleave)="this.itemOnHover = -1"
        >
          <img
            [src]="this.getSideMenuItemIcon(sideMenuItem)"
            [alt]="sideMenuItem.alt"
          />

          <span [hidden]="!this.isSideMenuOpen">{{
            sideMenuItem.translationPath | translate
          }}</span>
        </a>
      </li>
      } @else {
      <li
        [appShowFullText]="sideMenuItem.translationPath | translate"
        [placement]="'common.inline-end' | translate"
      >
        <a
          [routerLink]="sideMenuItem.route[0]"
          [ngClass]="{ active: this.isCurrentRouteActive(sideMenuItem) }"
          ariaCurrentWhenActive="page"
          [style.width]="this.isSideMenuOpen ? '90%' : 'fit-content'"
          (mouseenter)="this.itemOnHover = index"
          (mouseleave)="this.itemOnHover = -1"
        >
          <img
            [src]="this.getSideMenuItemIcon(sideMenuItem)"
            [alt]="sideMenuItem.alt"
          />

          <span [hidden]="!this.isSideMenuOpen">{{
            sideMenuItem.translationPath | translate
          }}</span>
        </a>
      </li>
      } } }
    </ul>
  </nav>
  <hr style="width: 82%; margin: 0 auto" />
  <nav class="secondary-nav-list">
    <ul>
      <li (click)="translate()">
        <a
          class="secondary-side-menu-item"
          [style.width]="this.isSideMenuOpen ? '90%' : 'fit-content'"
        >
          <img
            [src]="
              'assets/svgs/side-menu/' +
              (this.isRtl() ? 'en' : 'ar') +
              '-translate.svg'
            "
            alt="Translate"
          />

          <span [hidden]="!this.isSideMenuOpen">{{
            "sideMenu.translate" | translate
          }}</span>
        </a>
      </li>

      <li (click)="logout()">
        <a
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          class="secondary-side-menu-item"
          [style.width]="this.isSideMenuOpen ? '90%' : 'fit-content'"
        >
          <img src="assets/svgs/side-menu/logout.svg" alt="Logout" />

          <span [hidden]="!this.isSideMenuOpen">{{
            "sideMenu.logout" | translate
          }}</span>
        </a>
      </li>
    </ul>
  </nav>
</aside>
