<nav class="main-nav-list" [style.width]="this.isSideMenuOpen ? '75px' : '97%'">
  <ul>
    @for (sideMenuItem of sideMenuItems; track $index) {
    <li>
      <a
        [routerLink]="'/' + sideMenuItem.route[0]"
        [ngClass]="{ active: this.isCurrentRouteActive(sideMenuItem) }"
      >
        <img
          [src]="this.getDropDownItemIcon(sideMenuItem)"
          [alt]="sideMenuItem.alt"
        />
        <span [hidden]="this.isSideMenuOpen">{{
          sideMenuItem.translationPath | translate
        }}</span>
      </a>
    </li>
    }
  </ul>
</nav>
