<article class="faculty-sub-domain-container">
  <!-- <app-stat-cards-with-main-card
    [config]="{ cards: this.facultiesCard, mainCard: {name: this.selectedFaculty?.['faculty.name' | translate], img: this.selectedFaculty?.logo?.url ,manager: this.selectedFaculty?.dean?.['user.name' | translate]  } }"
  ></app-stat-cards-with-main-card> -->

  <div class="faculty-sub-domain">
    <app-faculties-details-side-menu
      [selectedFaculty]="selectedFaculty!"
    ></app-faculties-details-side-menu>

    <div class="sub-domain"><router-outlet></router-outlet></div>
  </div>
</article>
