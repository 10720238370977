<section class="aol-report-container">
  <div class="title">
    <div>
      {{ this.selectedCourseSection?.course?.["course.name" | translate] }} -
      {{ "domains.section" | translate }}
      {{ this.selectedCourseSection?.sectionOrder }} -
      {{ "aolReport.title" | translate }}
    </div>
  </div>
  <div class="switches">
    <button
      [ngClass]="{ active: this.currentPage === 'plo' }"
      (click)="switchToPloPage()"
    >
      PLOs {{ "common.achievements" | translate }}
    </button>
    <button
      (click)="switchToCloPage()"
      [ngClass]="{ active: this.currentPage === 'clo' }"
    >
      CLOs {{ "common.achievements" | translate }}
    </button>
  </div>
  <hr />
  <div>
    <div [hidden]="this.currentPage !== 'clo'">
      <app-clo-achievements
        [cloBenchmark]="this.selectedCourseSection?.course?.program?.evaluationCriteria?.[0]?.cloBenchmark!"
        [plos]="this.selectedCourseSection?.course?.plos!"
        [clos]="this.selectedCourseSection?.clos!"
        [courseName]="this.selectedCourseSection?.course?.nameEn!"
        [sectionOrder]="this.selectedCourseSection?.sectionOrder!"
        (onCloSelected)="this.onCloSelected($event)"
      ></app-clo-achievements>
    </div>
    <div [hidden]="this.currentPage !== 'plo'">
      <app-plo-achievements
        [ploBenchmark]="this.selectedCourseSection?.course?.program?.evaluationCriteria?.[0]?.ploBenchmark!"
        [plos]="this.selectedCourseSection?.course?.plos!"
        [courseName]="this.selectedCourseSection?.course?.nameEn!"
        [sectionOrder]="this.selectedCourseSection?.sectionOrder!"
      ></app-plo-achievements>
    </div>
    <div [hidden]="this.currentPage !== 'activities'">
      <app-activities [activities]="this.activities"></app-activities>
    </div>
  </div>
</section>
