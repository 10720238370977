import { Role } from '../../../../../shared/enums/role.enum';
import { SecondarySideMenuI } from '../../../../departments/interfaces/secondary-side-menu.interface';

export const SIDE_MENU_ITEMS: SecondarySideMenuI[] = [
  {
    translationPath: 'sideMenu.departments',
    activeIcon: 'assets/svgs/side-menu/active-department.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-department.svg',
    route: (facultyID: string | number) => `faculties/${facultyID}/departments`,
    alt: 'departments',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN],
  },
  {
    translationPath: 'sideMenu.programs',
    activeIcon: 'assets/svgs/side-menu/active-program.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-program.svg',
    route: (facultyID: string | number) => `faculties/${facultyID}/programs`,
    alt: 'Programs',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN],
  },
  {
    translationPath: 'sideMenu.courses',
    activeIcon: 'assets/svgs/side-menu/active-course.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-course.svg',
    route: (facultyID: string | number) => `faculties/${facultyID}/courses`,
    alt: 'Courses',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN],
  },
  {
    translationPath: 'sideMenu.students',
    activeIcon: 'assets/svgs/side-menu/active-student.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-student.svg',
    route: (facultyID: string | number) => `faculties/${facultyID}/students`,
    alt: 'Students',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN],
  },
  {
    translationPath: 'sideMenu.instructors',
    activeIcon: 'assets/svgs/side-menu/active-instructor.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-instructor.svg',
    route: (facultyID: string | number) => `faculties/${facultyID}/instructors`,
    alt: 'Instructors',
    roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.DEAN],
  },
];
