import { Component, OnDestroy, OnInit, effect } from '@angular/core';
import { CourseSectionsComponent } from './course-sections/course-sections.component';
import { Location, NgClass } from '@angular/common';
import { ActivatedRoute, RouterLinkActive } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CloAchievementsComponent } from './clo-achievements/clo-achievements.component';
import { PloAchievementsComponent } from './plo-achievements/plo-achievements.component';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../../core/services/toast/toast.service';
import { CoursesApiService } from '../courses-api.service';
import { CoursesService } from '../courses.service';
import {
  AolCourse,
  Clo,
  CourseSection,
  Plo,
} from '../interfaces/aol-course.interface';
import { DelegatedUIErrorI } from '../../../shared/interfaces/delegated-ui-error.interface';
import { SemesterType } from '../../academic-years/academic-year/enums/semester-type.enum';

@Component({
  selector: 'app-aol-course-details',
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    RouterLinkActive,
    CloAchievementsComponent,
    PloAchievementsComponent,
    CourseSectionsComponent,
  ],
  templateUrl: './aol-course-details.component.html',
  styleUrl: './aol-course-details.component.scss',
})
export class AolCourseDetailsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  selectedCourse: AolCourse | null = null;
  currentPage: 'clo' | 'plo' | 'course-sections' = 'plo';
  selectedPlo: Plo | null = null;
  selectedClo: Clo | null = null;
  courseSections: CourseSection[] = [];
  constructor(
    private readonly coursesApiService: CoursesApiService,
    private readonly coursesService: CoursesService,
    private readonly location: Location,
    private readonly toastService: ToastService,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {
    effect(() => {
      this.selectedPloListener();
    });
  }
  ngOnInit(): void {
    this.getDataFromRoute();
  }

  onCloSelected(clo: Clo) {
    this.selectedClo = clo;
    this.courseSections = this.selectedCourse!.courseSections.filter(
      (courseSection) =>
        courseSection.activities.find((activity) =>
          activity.questions.find((question) => question.clo === clo._id)
        )
    );
    this.currentPage = 'course-sections';
  }

  getDataFromRoute() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const courseID = paramMap.get('courseID');
      if (!courseID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.fetchQueryParams(courseID);
      }
    });
  }

  fetchQueryParams(courseID: string) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        const { academicYearID, semesterType } = params;

        this.fetchCourse(courseID, academicYearID, +semesterType);
      });
  }

  switchToPloPage() {
    this.currentPage = 'plo';
  }

  switchToCloPage() {
    this.currentPage = 'clo';
  }

  selectedPloListener() {
    if (!!this.coursesService.selectedPlo()) this.switchToCloPage();
  }

  fetchCourse(
    courseID: string,
    academicYearID: string,
    semesterType: SemesterType
  ) {
    this.coursesApiService
      .aol(courseID, academicYearID, semesterType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedCourse = data;
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
