<section class="aol-report-container">
  <div class="title">
    <div>
      {{ this.selectedActivity?.name }} - {{ "aolReport.title" | translate }}
    </div>
  </div>
  <div class="switches">
    <button
      routerLink="./activity-report"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
    >
      {{ "aolReport.title" | translate }}
    </button>
    <button
      routerLink="./clo-achievements"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
    >
      CLOs Achievements
    </button>
  </div>
  <hr />
  <router-outlet></router-outlet>
</section>
