import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { SIDE_MENU_ITEMS } from './constants/side-menu-items.constant';
import { Router, RouterLink } from '@angular/router';
import { NavItemI } from '../../../shared/interfaces/nav-item.interface';
import { DOCUMENT, NgClass, isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-settings-side-menu',
  standalone: true,
  imports: [RouterLink, NgClass, TranslateModule],
  templateUrl: './settings-side-menu.component.html',
  styleUrl: './settings-side-menu.component.scss',
})
export class SettingsSideMenuComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformID: Object,
    private readonly router: Router
  ) {}
  sideMenuItems = SIDE_MENU_ITEMS;
  isSideMenuOpen = false;

  ngOnInit(): void {
    this.initScreenType();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event & { target: { innerWidth: number } }) {
    this.isSideMenuOpen =
      event.target?.innerWidth <= 700 && event.target?.innerWidth >= 500;
  }

  initScreenType() {
    if (isPlatformBrowser(this.platformID)) {
      this.isSideMenuOpen =
        this.document.defaultView!.innerWidth <= 700 &&
        this.document.defaultView!.innerWidth >= 500;
    }
  }

  public getDropDownItemIcon(dropDownItem: NavItemI) {
    return dropDownItem.route[0] === this.router.url.slice(1)! ||
      (this.router.url.slice(1) === undefined && dropDownItem.alt === 'Home')
      ? dropDownItem.activeIcon
      : dropDownItem.nonActiveIcon;
  }

  public isCurrentRouteActive(dropDownItem: NavItemI) {
    return dropDownItem.route[0] === this.router.url.slice(1);
  }
}
