import { Component } from '@angular/core';
import { HeaderLoggedInUserComponent } from './header-logged-in-user/header-logged-in-user.component';
import { HeaderUniversityComponent } from './header-university/header-university.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [HeaderUniversityComponent, HeaderLoggedInUserComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {}
