import { Routes } from '@angular/router';
import { loggedInGuard } from './core/guards/logged-in/logged-in.guard';
import { loggedOutGuard } from './core/guards/logged-out/logged-out.guard';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { BaseComponent } from './pages/base/base.component';
import { DepartmentsDetailsComponent } from './pages/departments/departments-details/departments-details.component';
import { FacultiesDetailsComponent } from './pages/faculties/faculties-details/faculties-details.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ProgramsDetailsComponent } from './pages/programs/programs-details/programs-details.component';
import { CoursesDetailsComponent } from './pages/courses/courses-details/courses-details.component';
import { ActivityDetailsComponent } from './pages/activities/activity-details/activity-details.component';
import { CourseSectionDetailsComponent } from './pages/course-sections/course-section-details/course-section-details.component';
import { AolCourseDetailsComponent } from './pages/courses/aol-course-details/aol-course-details.component';
import { SettingsComponent } from './pages/settings/settings.component';

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },
      { path: 'home', redirectTo: '' },
      {
        path: 'faculties',
        title: 'Faculties',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/faculties/all-faculties/all-faculties.component'
              ).then((m) => m.AllFacultiesComponent),
          },
          {
            path: ':facultyID',
            component: FacultiesDetailsComponent,
            children: [
              {
                path: 'departments',
                loadComponent: () =>
                  import(
                    './pages/faculties/faculties-details/faculty-departments/faculty-departments.component'
                  ).then((m) => m.FacultyDepartmentsComponent),
              },
              {
                path: 'programs',
                loadComponent: () =>
                  import(
                    './pages/faculties/faculties-details/faculty-programs/faculty-programs.component'
                  ).then((m) => m.FacultyProgramsComponent),
              },
              {
                path: 'courses',
                loadComponent: () =>
                  import(
                    './pages/faculties/faculties-details/faculty-courses/faculty-courses.component'
                  ).then((m) => m.FacultyCoursesComponent),
              },
              {
                path: 'students',
                loadComponent: () =>
                  import(
                    './pages/faculties/faculties-details/faculty-students/faculty-students.component'
                  ).then((m) => m.FacultyStudentsComponent),
              },
              {
                path: 'instructors',
                loadComponent: () =>
                  import(
                    './pages/faculties/faculties-details/faculty-instructors/faculty-instructors.component'
                  ).then((m) => m.FacultyInstructorsComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'departments',
        title: 'Departments',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/departments/all-departments/all-departments.component'
              ).then((m) => m.AllDepartmentsComponent),
          },
          {
            path: ':departmentID',
            component: DepartmentsDetailsComponent,
            children: [
              {
                path: 'programs',
                loadComponent: () =>
                  import(
                    './pages/departments/departments-details/department-programs/department-programs.component'
                  ).then((m) => m.DepartmentProgramsComponent),
              },
              {
                path: 'courses',
                loadComponent: () =>
                  import(
                    './pages/departments/departments-details/department-courses/department-courses.component'
                  ).then((m) => m.DepartmentCoursesComponent),
              },
              {
                path: 'students',
                loadComponent: () =>
                  import(
                    './pages/departments/departments-details/department-students/department-students.component'
                  ).then((m) => m.DepartmentStudentsComponent),
              },
              {
                path: 'instructors',
                loadComponent: () =>
                  import(
                    './pages/departments/departments-details/department-instructors/department-instructors.component'
                  ).then((m) => m.DepartmentInstructorsComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'programs',
        title: 'Programs',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/programs/all-programs/all-programs.component'
              ).then((m) => m.AllProgramsComponent),
          },
          {
            path: ':programID',
            component: ProgramsDetailsComponent,
            children: [
              {
                path: 'courses',
                loadComponent: () =>
                  import(
                    './pages/programs/programs-details/program-courses/program-courses.component'
                  ).then((m) => m.ProgramCoursesComponent),
              },
              {
                path: 'students',
                loadComponent: () =>
                  import(
                    './pages/programs/programs-details/program-students/program-students.component'
                  ).then((m) => m.ProgramStudentsComponent),
              },
              {
                path: 'plos',
                loadComponent: () =>
                  import(
                    './pages/programs/programs-details/program-plos/program-plos.component'
                  ).then((m) => m.ProgramPlosComponent),
              },
              {
                path: 'evaluation-criteria',
                loadComponent: () =>
                  import(
                    './pages/programs/programs-details/program-evaluation-criteria/program-evaluation-criteria.component'
                  ).then((m) => m.ProgramEvaluationCriteriaComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'aol-programs',
        title: 'AoL Programs',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/programs/aol-programs/aol-programs.component'
              ).then((m) => m.AolProgramsComponent),
          },
          {
            path: ':programID',
            loadComponent: () =>
              import('./pages/programs/aol-report/aol-report.component').then(
                (m) => m.AolReportComponent
              ),
          },
        ],
      },
      {
        path: 'courses',
        title: 'Courses',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/courses/all-courses/all-courses.component').then(
                (m) => m.AllCoursesComponent
              ),
          },
          {
            path: 'aol/:courseID/corrective-actions',
            loadComponent: () =>
              import(
                './pages/courses/corrective-actions/corrective-actions.component'
              ).then((m) => m.CorrectiveActionsComponent),
          },
          {
            path: 'aol/:courseID',
            component: AolCourseDetailsComponent,
            children: [
              {
                path: 'plo-achievements',
                loadComponent: () =>
                  import(
                    './pages/courses/aol-course-details/plo-achievements/plo-achievements.component'
                  ).then((m) => m.PloAchievementsComponent),
              },
              {
                path: 'clo-achievements',
                loadComponent: () =>
                  import(
                    './pages/courses/aol-course-details/clo-achievements/clo-achievements.component'
                  ).then((m) => m.CloAchievementsComponent),
              },
              {
                path: 'course-sections',
                loadComponent: () =>
                  import(
                    './pages/courses/aol-course-details/course-sections/course-sections.component'
                  ).then((m) => m.CourseSectionsComponent),
              },
            ],
          },
          {
            path: ':courseID',
            component: CoursesDetailsComponent,
            children: [
              {
                path: 'clos',
                loadComponent: () =>
                  import(
                    './pages/courses/courses-details/course-clos/course-clos.component'
                  ).then((m) => m.CourseClosComponent),
              },
            ],
          },
          {
            path: 'academic-years/:academicYearID/semesters/:semesterType',
            loadComponent: () =>
              import(
                './pages/courses/academic-year-courses/academic-year-courses.component'
              ).then((m) => m.AcademicYearCoursesComponent),
          },
          {
            path: ':courseID/academic-years/:academicYearID/semesters/:semesterType/sections',
            loadComponent: () =>
              import(
                './pages/course-sections/academic-year-course-sections/academic-year-course-sections.component'
              ).then((m) => m.AcademicYearCourseSectionsComponent),
          },
        ],
      },

      {
        path: 'academic-years',
        title: 'Academic Years',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/academic-years/academic-year/academic-year.component'
              ).then((m) => m.AcademicYearComponent),
          },
        ],
      },
      {
        path: 'activities',
        children: [
          {
            path: 'course-sections/:courseSectionID',
            loadComponent: () =>
              import(
                './pages/activities/course-section-activities/course-section-activities.component'
              ).then((m) => m.CourseSectionActivitiesComponent),
          },
          {
            path: ':activityID/grades',
            loadComponent: () =>
              import(
                './pages/activities/activity-grades/activity-grades.component'
              ).then((m) => m.ActivityGradesComponent),
          },
          {
            path: ':activityID/questions',
            loadComponent: () =>
              import(
                './pages/activities/activity-questions/activity-questions.component'
              ).then((m) => m.ActivityQuestionsComponent),
          },
          {
            path: ':activityID/aol',
            component: ActivityDetailsComponent,
            children: [
              {
                path: 'activity-report',
                loadComponent: () =>
                  import(
                    './pages/activities/activity-details/activity-report/activity-report.component'
                  ).then((m) => m.ActivityReportComponent),
              },
              {
                path: 'clo-achievements',
                loadComponent: () =>
                  import(
                    './pages/activities/activity-details/clo-achievements/clo-achievements.component'
                  ).then((m) => m.CloAchievementsComponent),
              },
              { path: '', redirectTo: 'activity-report', pathMatch: 'full' },
            ],
          },
        ],
      },
      {
        path: 'course-sections',
        children: [
          {
            path: ':courseSectionID/aol',
            component: CourseSectionDetailsComponent,
            children: [
              {
                path: 'plo-achievements',
                loadComponent: () =>
                  import(
                    './pages/course-sections/course-section-details/plo-achievements/plo-achievements.component'
                  ).then((m) => m.PloAchievementsComponent),
              },
              {
                path: 'clo-achievements',
                loadComponent: () =>
                  import(
                    './pages/course-sections/course-section-details/clo-achievements/clo-achievements.component'
                  ).then((m) => m.CloAchievementsComponent),
              },
              {
                path: 'clo-achievements/:cloID/activities',
                loadComponent: () =>
                  import(
                    './pages/course-sections/course-section-details/activities/activities.component'
                  ).then((m) => m.ActivitiesComponent),
              },
              { path: '', redirectTo: 'plo-achievements', pathMatch: 'full' },
            ],
          },
        ],
      },
      {
        path: 'students',
        title: 'Students',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/students/all-students/all-students.component'
              ).then((m) => m.AllStudentsComponent),
          },
          {
            path: 'course-sections/:courseSectionID',
            loadComponent: () =>
              import(
                './pages/students/course-section-students/course-section-students.component'
              ).then((m) => m.CourseSectionStudentsComponent),
          },
        ],
      },
      {
        path: 'instructors',
        title: 'Instructors',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/instructors/all-instructors/all-instructors.component'
              ).then((m) => m.AllInstructorsComponent),
          },
        ],
      },
      {
        path: 'settings',
        title: 'Settings',
        children: [
          {
            path: '',
            component: SettingsComponent,
            children: [
              {
                path: 'change-password',
                loadComponent: () =>
                  import(
                    './pages/settings/change-password/change-password.component'
                  ).then((m) => m.ChangePasswordComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'profile',
        title: 'Profile',
        loadComponent: () =>
          import('./pages/profile/profile.component').then(
            (m) => m.ProfileComponent
          ),
      },

      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [loggedOutGuard],
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('./pages/auth/login/login.component').then(
            (m) => m.LoginComponent
          ),
      },
      {
        path: 'first-time-login',
        loadComponent: () =>
          import(
            './pages/auth/first-time-login/first-time-login.component'
          ).then((m) => m.FirstTimeLoginComponent),
      },
      {
        path: 'reset-password',
        loadComponent: () =>
          import('./pages/auth/reset-password/reset-password.component').then(
            (m) => m.ResetPasswordComponent
          ),
      },
      {
        path: 'forget-password',
        loadComponent: () =>
          import('./pages/auth/forget-password/forget-password.component').then(
            (m) => m.ForgetPasswordComponent
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
    ],
  },
  {
    path: 'error/404',
    component: PageNotFoundComponent,
  },
  { path: '**', redirectTo: 'error/404', pathMatch: 'full' },
];
