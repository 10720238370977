import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event as RouterEvent,
  RouterOutlet,
} from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConnectivityService } from './core/services/connectivity/connectivity.service';
import { TranslationService } from './core/services/translate/translation.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgIf, AsyncPipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy, OnInit {
  public showOverlay = true;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public readonly translationService: TranslationService,
    private readonly connectivityService: ConnectivityService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.navigationListener();
  }
  navigationListener() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe({
      next: (event: RouterEvent) => {
        this.navigationInterceptor(event);
      },
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.connectivityService.stopChecking();
  }
}
