import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SettingsSideMenuComponent } from './settings-side-menu/settings-side-menu.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [RouterOutlet, SettingsSideMenuComponent],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent {}
