<section class="aol-report-container">
  <div class="title">
    <div>
      {{ this.selectedCourse?.["course.name" | translate] }} -
      {{ "aolReport.title" | translate }}
    </div>
  </div>
  <div class="switches">
    <button [ngClass]="{ active: this.currentPage === 'plo' }" (click)="switchToPloPage()">
      PLOs {{ "common.achievements" | translate }}
    </button>
    <button (click)="switchToCloPage()" [ngClass]="{ active: this.currentPage === 'clo' }">
      CLOs {{ "common.achievements" | translate }}
    </button>
  </div>
  <hr />
  <div>
    <div [hidden]="this.currentPage !== 'clo'">
      <app-course-clo-achievements [cloBenchmark]="this.selectedCourse?.program?.evaluationCriteria?.[0]?.cloBenchmark!"
        [plos]="this.selectedCourse?.plos!" [clos]="this.selectedCourse?.clos!"
        [courseName]="this.selectedCourse?.nameEn!"
        (onCloSelected)="this.onCloSelected($event)"></app-course-clo-achievements>
    </div>
    <div [hidden]="this.currentPage !== 'plo'">
      <app-course-plo-achievements [ploBenchmark]="this.selectedCourse?.program?.evaluationCriteria?.[0]?.ploBenchmark!"
        [plos]="this.selectedCourse?.plos!" [courseName]="this.selectedCourse?.nameEn!"></app-course-plo-achievements>
    </div>
    <div [hidden]="this.currentPage !== 'course-sections'">
      <app-course-sections [courseSections]="this.courseSections"
        [cloBenchmark]="this.selectedCourse?.program?.evaluationCriteria?.[0]?.cloBenchmark!"
        [clo]="this.selectedClo!"></app-course-sections>
    </div>
  </div>
</section>