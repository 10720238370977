import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Location } from '@angular/common';
import { StorageService } from '../../services/storage/storage.service';

export const loggedOutGuard: CanActivateFn = (_route, _state) => {
  const storageService = inject(StorageService);
  const location = inject(Location);

  if (storageService.accessToken) {
    location.back();
    return false;
  }
  return true;
};
